import React from "react"
import {
  Col,
  Container,
  Jumbotron,
  Row
} from 'reactstrap'
import 'react-vertical-timeline-component/style.min.css';
import ReactMarkdown from "react-markdown";
import {FaExclamationTriangle} from 'react-icons/fa'
import {graphql} from "gatsby";

import 'leaflet/dist/leaflet.css'
import {TileLayer, Marker, Popup, Polyline} from 'react-leaflet'
import polyUtil from 'polyline-encoded';
import * as turf from '@turf/turf';

import moment from 'moment';

import {LangContext} from "../components/kbusWrapper";
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from '../components/pageTitle'
import Map from "../components/map"

import MapMarkerParadaIcon from "../components/mapMarkerParadaIcon"

import kbusBanner from '../assets/images/banner/banner_home.png';

const redOptions = { color: '#BB2218', smoothFactor: 0.1 }

const BloqueIncidencia = ({title, date, children}) => (
  <div className="pt-4 pb-4">
    <h4 className="text-uppercase text-center font-weight-bold">{title}</h4>
    <p className="text-muted text-center">{date}</p>
    <ReactMarkdown source={children} className="text-muted pt-4"/>
  </div>
)

const IndexPage = ({data}) => {
  const buildIncidencias = (incidencias, noIncidencias) => {
    return incidencias.length < 2 ?
      (<p className="text-muted text-center pt-3">{noIncidencias}</p>) :
      incidencias.slice(1)
        .sort((incidenciaA, incidenciaB) => {
          return moment(incidenciaA.fecha, "DD/MM/YYYY").isBefore(moment(incidenciaB.fecha, "DD/MM/YYYY")) ? 1 : -1;
        }).map((incidencia) =>
          <BloqueIncidencia key={incidencia.id} title={incidencia.titulo} date={incidencia.fecha} children={incidencia.descripcion} />
        )
  }
  
const geometriasRutas = {
    'KBus1': [
      "mfhgGhkeQVM\\GRBVOP[pAk@xBgApJqEbI{DZMZCVNNALKJWHe@hAkGj@yCf@gBh@y@l@o@dBaBr@e@z@]t@Ud@Ep@D\\B\\JfGhBxApAd@`A?n@Sn@Qb@SDKT@b@@v@ChBe@rAwAfFFp@~@DdHtAfAn@t@fArArBbBxAdEbDfEtD`@|@@xAH`@VV\\E|AmAhBoAhFuAp@Qt@AfABbATXl@l@BLw@Qa@a@@Ic@_AmCs@oB{AuDG_@@a@R_@bEY|C{@`JqC~@_@`@a@^kBhCyKVO@m@[[]NC^Dd@yChNQ^WP}@Z_Bj@cIdCmARy@JsADaALi@PCZ@^NX\\j@tAjD|AzD`@tASJEXXh@\\JV`BX|Ad@`Ct@rCKJCPANWVY\\U\\IZCP?PDr@PrCDp@CNQA]S_A]_@Ci@h@Yb@Qd@e@hAOf@AT?XD^@b@?`@MNSB[Ss@g@e@tB}@vDUP~AzFpAxE@\\GLEL?RBL@LKf@Ml@Gb@QvAIVWXMFSCiCaAkDoA_@SOSAURcAXgBHsA@uAC_AUkCk@eFq@uGQeAQw@Oe@Q_@Q[a@k@k@k@e@Ye@Ui@QYSO]QIQ?ULKRSN]FYCSCYOuAoAo@a@c@Qi@K_@Am@Am@Jc@Tk@n@]|@]lAUv@Yd@[JUBQOW@SLOVCXBZJRNLBPRhEj@nHv@bIb@lE^zC^rBz@xDf@|Bv@jDz@pDEN@P@NDPJPO^gA|Bi@lBu@a@sEW[AM@c@NBVL`BH`A?rAKhAWlA]dAi@z@g@f@a@Xi@Pm@Dq@@s@Kq@Us@i@k@}@i@uAWqAEgA?yABq@Hy@Fw@@{@C}@c@wNJOLWWYIQQkECWMKQAs@Ag@Aa@IgCw@wAi@}EmB]MWSa@IIQOCKDIMOYe@mC[}Ai@b@o@f@i@\\]ROHOLW`@U@cFeA}A[m@Om@Se@Qa@QUQSOQYC]@SFW^o@|@cB`@{@d@{@zAiCHI?Q@Qv@iH?OIAMEES@UIM}CuDqEiFoB{BKSCU?[xA_G\\QLMLUD]Rc@\\UhB{@lDaB",
      "ikhgGbmeQiAj@qAj@]JWOOCSBOHKREZ@^J\\PLPDRERMJ[N]VWdBs@bB`ImDfHe@|@fInJEVDRJJAVIr@o@lFILEH@TkAnBuAbCSh@Cb@Wf@Yd@IZAZDZNR\\RBVENS@[JKRGPC`@AhB?vB?dA@\\FHTMtM{G`AHtAXpBd@~A`@\\X@RFNRDTOn@GtAh@dDpA~An@?V?XB^L|BV|BFb@FLV?n@C\\CZ~OJnC@p@Eh@G^EPOFALHPEl@B~@HpAPrAh@`Bn@zA`ArAfAv@TLLVHXLLR?RSZDt@H~@@lASf@Kj@Sl@_@p@m@l@s@j@_AjAgCj@iB^wALsAJwAPq@l@eC|@aC^u@Vg@LBNCLODO?]CUIKQIMm@{@cE}@cEAUGUK[UqAcAcFq@oDc@{DSaCMeBWwCOsCQgEAc@B[L[NUT_@b@cA\\eAZcA`@{@j@e@x@O`AD`ATlAp@jA`ATN\\LdADJXTJZCRW`@?l@HdAh@h@n@l@dA`@pAf@xD`@~Db@xDNzAJ|A@xAAn@Ej@El@Mj@c@zAMAQDIRGRBb@LTHFL@LAJI`@EzBz@dEdBZTRNPMHm@PsAH_ANu@R_AP@NGFUCWGQMAIMGa@Ka@_@uAs@iCyA{E?k@Oo@{@{Ca@_BU}@EYE[HULOP?RHZZz@|@p@h@b@^b@XbAv@NFP@NODOAa@Ge@Ca@@a@Lg@Ti@f@iA\\i@`@_@VBXF|@^p@P`@Db@@TCTMFWJ_H@]?_@M[GOIEM??MCMOIKAMc@[sA_@yAUmAUoAKs@@]JWDa@EYKUSK[SSo@Qs@y@wBmB{EEWAWD[HSzD_@`@ItA[nBo@pEuAnBm@VKPONKJQRy@rCaMRIHW?UIUOIWDGRATFXqAbGm@rCU~@Wj@Y\\_@Ra@LoKdDiB^cDVm@Fm@LuDpB}CnB{AnA{@z@s@dAs@vAg@?MLcA{@wBcBaEkDm@_A[k@u@cAoAw@eDm@}C{@O[CO?OXcAjAsDJ[B]BaB@[@YLQB]l@{A@q@C[GO}AwAi@U}C_AqAc@qAWs@Es@Hy@V{@b@eAz@sBtBe@v@Yv@]dBw@nDi@pBg@n@SHONIRc@TwAh@_D|A}C`BuHlDwEzBQEM@KJENCNWLo@V{B`A"
    ],
    'KBus2': [
      "qr`gGb_mQDQU_@Ia@Ec@Ce@Ia@W_@WQoBuAoBuA][QYQc@eA{FE]B[V[LWHk@P{A^}@d@}@`BoFD]@a@A_@Ge@WkA]sAs@yB]_AWa@SMUGc@KYIo@k@e@i@Qc@Ga@IeAM}@Se@s@w@u@m@cAy@_@]QWSc@Mg@Ii@Ag@DqANwAX_D?iAOiAe@oAUk@Om@MsAAy@C_@Mk@S_@Y_@g@s@??mJsLa@w@W}@I}@?s@Dm@Li@\\_B^iBBq@Cq@I}@Ku@c@aBYi@[c@k@]g@Og@Ee@Ak@Hk@Pg@Pc@?w@Qw@Ws@W{@a@y@_@cBoA{AeAc@YYOQGSEW?OB_@FWLa@X]b@k@p@k@`@]L_@DmAJe@H[Fe@Jc@?c@QYYw@cAoAaAo@g@]_@Wg@Qg@Kg@Qa@Qg@KOa@Ui@Be@Fa@Dc@AeDEgA?mALwA\\kAh@a@N]Bo@IKMOCKHGLATFVMj@M`@E^WpBO^MHSFYGq@W_Bo@iDqAQQIMASMSMIQEMDMRIR@ZFZMf@a@x@oCrG}AnDuA~CWn@WJe@D}ABw@FKGCSIMMEKWO}@oBiJCS?OIQGOUqAk@qCWsA]cBQ{@g@yE_@oE_@cEOkDGqBCmAJW@a@Ea@QUIYE]Eu@AcCAa@G[QW[KMBcBt@mCnAiB|@u@`@ITKJSPWVOXK`@Eb@S^]DMEGMCU@SPk@Vu@To@J[@a@G[IO_@Y]MqAMuAMc@Ka@QmAy@uCwCWMQAUDIJGFUHOKOOg@yAo@oDOq@Ue@_@c@][][i@So@MU?IJ{@zE{@KS@ETMt@Ot@WzB",
      "y`hgGz_fQq@dF@RHLzEKdDG~AK`BIJF?d@Az@?xA?p@IlAIx@Ol@[hBq@fF{@`CCR@PPf@v@|B`@bAVl@PJPB\\G`@MtGiFDNJJN@JGNKJSBOCWGSMKTu@~@oCv@Bp@MVSZw@`@Nd@DrAo@~FyB|@_@p@UZ@RDPNDP@l@N|CVdDZ~CN|AJhAvKrD|LjEdDnA?N@PDPLJN@LATOV?`Cz@jBr@`Bp@PRPNFBHEHSB]NsANsALq@Rs@JDNCHSFMLANAd@EZQ|@]d@Q\\KrAUdBGvBFlAD^A\\G\\CXDVJ^b@Rb@Vv@Tn@P\\XXp@f@jAdAv@bATTVNPHRAf@Az@UzAOj@Eb@OTU\\Yn@y@TYTOb@Qh@G^BZN`@Rr@d@`An@~AlA`@Td@RtAj@`A\\`@LXBRDTGh@Qf@Qf@GtAHh@P`@X`@d@Xp@\\vATrAFx@Cx@Kx@Qp@]rAWxAAj@@n@Hl@Lh@Rh@^n@jCdDlErFf@n@Z`@NTP`@Lr@@z@?n@Lt@Pn@LZPZZ`AHd@Dh@?l@Ep@OpA[rDAp@Hn@Jh@Nd@PZ`@`@dAv@~@t@n@r@NVHb@FZ?\\F`AJ\\NX\\d@n@j@\\Lb@JZFTTV^Zz@b@|AVt@X`AThADd@@d@CTCTEROj@Wr@Yz@Ut@Wn@g@~@IXI\\Gd@Eh@IZIRMTKVAN?NF`@XtAf@fCH^N\\l@j@nAz@hD~BNNJXD\\@f@B\\DTXf@IP"
    ],
    'KBus3': [
      "wj_gG~vcQ[FSCMKIM]o@e@o@QSSKUCWC_@Mo@Mi@KWCQOQUQWQQUOWMc@K]@]H_@TYP[LUD[LSJQDSRcAjAiAlAwA|Aw@t@k@j@_@b@W`@_@^]Ta@TQ@OEMJW\\Wb@]f@SXWZ[XYR[H_@Hc@X]T[XSRQB]A_@Ee@I]O_@QYSUUYY_@Sk@SaAa@e@Oc@Gi@Ac@Da@Hc@Na@P_Af@{Ax@y@d@e@Pc@P[T_@N{A|@c@X]Lk@@q@?qBCsBKc@EYEWDqAXkAl@c@Z[Fe@@a@Ae@@SJYLW?UB]D[PWh@e@Fo@EiAKgA?oAHu@Vw@Cq@Hq@To@H}@ViBx@o@Fm@?e@EIEIGCQDOT]PYLk@r@{C\\}AXqA`@}ADSNEFKBOBOCOEOIGIAMBIFELAL?NBLANMp@SdAq@|Cy@vDO`@SX]X]NiLnDs@Pe@FqALmCRYHGVFf@HRLNp@vA|@vBl@~An@`BXhAMNGXBRHRNHRA`@jCf@fCdAfEKP?TLPLAHMBSTYNc@F_@NMXOhAk@l@]h@g@b@o@`@u@t@wAlAyCP]NKFMAOGIAOHQ\\_Ad@iAl@sAt@iA`CcBl@UzA_@`Be@RMLQJQP[\\M^C`@I",
      "macgGx{dQm@JY@O@IHQRQ^c@^_Bb@uA\\y@Z{B~A_@h@Yh@g@bAe@dAa@fACPANIDEJ@LENSb@_@|@e@`AYr@[j@i@`A_@f@[ZWP[R[Pa@R]RUJKLIRGXM^KFKGM?EUSq@WaAu@mDOgAKo@JYHS?W?[G[MWWKUAKQKi@Qk@g@wAwBoFG[AQAQBODKJKl@Ih@EfAGVE\\G^IdAYfA]dCu@jA_@j@Od@O\\MJ@FHLBN@x@Bj@Bf@?^KnAg@bA_@j@Md@QXKXEX?d@@b@Ev@Sr@Gd@Ah@@vAHXBX@NGJSLSLGr@QT?VATOXG^Ad@@P@PCRKbAi@d@WnAW\\K^DhCPxB@jA?T?VKf@[~A_A\\MXWTKRCXO|CcB`Ag@`A_@l@Kv@C^Db@NpAd@fAd@r@p@f@Zh@Vj@Jf@Ff@CVYVSd@]f@Un@Ov@m@h@u@h@{@^m@PMHBHDLCJE^Ub@_@\\]Vc@z@aAvBsBrDeE`Ac@j@OtAu@f@Ah@Ff@ZZXZ`@NPRFbCf@r@JZVf@r@T\\R`@HLN@b@E"
    ],
  }

  const lineasData = data.lineas.edges.map((linea) => {

    const rutasData = linea.node.rutas.map((ruta, rutaIndex) => {
      const rutaPoints = ruta.paradas.map((parada) => {
        return [parseFloat(parada.coord.lat), parseFloat(parada.coord.lon)];
      });

      const rutaMarkers = ruta.paradas.map((parada, paradaIndex) => {
        const key = `${linea.node.nombre}-${rutaIndex}-${paradaIndex}`
        return (
          <Marker position={[parada.coord.lat, parada.coord.lon]} icon={MapMarkerParadaIcon()} key={key}>
            <Popup>
              <span className="font-weight-bold">{parada.nombre}</span>
              <br/>
              <span>{linea.node.nombre}</span>
            </Popup>
          </Marker>
        )
      });

      return {
        points: rutaPoints,
        markers: rutaMarkers
      }
    })

    return {
      nombre: linea.node.nombre,
      markers: rutasData[0].markers.concat(rutasData[1].markers),
      points: rutasData[0].points.concat(rutasData[1].points),
      polylines: geometriasRutas[linea.node.label]
    }
  });

  const nuestrasLineas = lineasData.map((linea, index) => {
    const features = turf.points(linea.points);
    const _center = turf.center(features);

    return (
        <Row key={index} className="pt-3 mb-3 border-bottom pb-5">
          <Col xs={12} className="pt-3 mb-3">
            <h5 className="text-center font-weight-bold pb-3">{linea.nombre}</h5>
          </Col>
          <Col xs={12} style={{height: '500px'}}>
            <Map centroMapa={_center.geometry.coordinates} zoomMapa={13} styleMapa={{height: '100%'}}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {linea.markers}
              {linea.polylines.map((polyline, index) => {
                const positions = polyUtil.decode(polyline);
                return <Polyline key={index} pathOptions={redOptions} positions={positions} />
              })}
            </Map>
          </Col>
        </Row>
    )
  })

  return (
    <LangContext.Consumer>
      { context => (
        <Layout>
          <SEO title={context.translate(data, 'home', 'titulo')} />
          <Jumbotron className="w-100 overflow-hidden">
            <img src={kbusBanner} alt="KBus - Barakaldo Bus" className="kbus-home-banner"/>
          </Jumbotron>

          <Container className="pt-5 mb-5">
            <Row>
              <Col xs={{size: 12, offset:0}} md={{size:8, offset:2}} lg={{size:6, offset:3}}>
                <h4 className="text-danger text-center font-weight-bold border-bottom pb-3"><FaExclamationTriangle className="mb-1 mr-2"/><u>{context.translate(data, 'incidencias', 'titulo')}</u></h4>
                {buildIncidencias(context.translate(data, 'incidencias', 'incidencias'), context.translate(data, 'incidencias', 'noIncidencias'))}
              </Col>
            </Row>
          </Container>

          <Container className="pt-3 mb-5">
            <PageTitle title={context.translate(data, 'home', 'nuestrasLineas')}/>
            {nuestrasLineas}
          </Container>

        </Layout>
      )}
    </LangContext.Consumer>
  )
}

export default IndexPage

export const pageQuery = graphql`  
  query {
    home: strapiHome {
      titulo
      nuestrasLineas
    }
    home_eus: strapiHomeEus {
      titulo
      nuestrasLineas
    }
    incidencias: strapiIncidencias {
      noIncidencias
      titulo
      incidencias {
        id
        titulo
        fecha
        descripcion
      }
    }
    incidencias_eus: strapiIncidenciasEus {
      noIncidencias
      titulo
      incidencias {
        id
        titulo
        fecha
        descripcion
      }
    },
    lineas: allStrapiLineas {
      edges {
        node {
          label
          nombre
          rutas {
            direccion
            nombre
            paradas {
              nombre
              label
              coord {
                lat
                lon
              }
            }
          }
        }
      }
    }
  }
`
